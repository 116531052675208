import React, { useState, useEffect } from 'react'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { LogoImage } from '../../icons'
import { UserDropDownMenu } from './UserDropDownMenu'
import {
  BrandLogoWrapper,
  Hamburger,
  HamburgerIcon,
  HamburgerMenu,
  HeaderContainer,
  HeaderInnerContainer,
  HeaderWrapper
} from './Header.styled'

import settings from '../../settings'
import { tr, useWindowSize } from 'mmfintech-commons'
import { actions, isOwnerOrAdministrator, paths } from 'mmfintech-backend-api'

import { LoginStatusEnum } from 'mmfintech-commons-types'

const MAX_MOBILE_WIDTH = 1176

export const Header = () => {
  const { userStatus, merchant, customerRole } = useSelector(
    ({ user: { userStatus, merchant, customerRole } }: any) => ({
      customerRole,
      userStatus,
      merchant
    }),
    shallowEqual
  )

  const [opened, setOpened] = useState(false)
  const [visible, setVisible] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  const { apiEnabled } = merchant || {}

  const [width] = useWindowSize()

  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN
  const isHamburgerVisible = () => width < MAX_MOBILE_WIDTH
  const isDevelopersMenuVisible = () => !!apiEnabled && isOwnerOrAdministrator(customerRole)

  const hideMenu = () => opened && setOpened(false)

  const handleLogoutClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    hideMenu()
    history.push(paths.login())
    dispatch(actions.auth.logout())
    return false
  }

  const BrandLogoImage = () => <LogoImage height='6rem' />

  const BrandLogo = () => {
    return (
      <BrandLogoWrapper>
        {isLoggedIn() ? (
          <Link to={paths.dashboard()}>
            <BrandLogoImage />
          </Link>
        ) : (
          <a href={settings.landingPageUrl}>
            <BrandLogoImage />
          </a>
        )}
      </BrandLogoWrapper>
    )
  }

  const MainMenuItems = () => (
    <>
      <NavLink activeClassName='active-tab' to={paths.dashboard()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.OVERVIEW', 'Overview')}
      </NavLink>
      <NavLink activeClassName='active-tab' to={paths.banking.accounts.list()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.ACCOUNTS', 'Accounts')}
      </NavLink>
      <NavLink activeClassName='active-tab' to={paths.banking.transactions.list()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.TRANSACTIONS', 'Transactions')}
      </NavLink>
      <NavLink activeClassName='active-tab' to={paths.banking.expenses()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.EXPENSES', 'Expenses')}
      </NavLink>
    </>
  )

  const UserMenuItems = ({ className = undefined, onClick }) => (
    <>
      <NavLink to={paths.profile()} onClick={onClick} className={className}>
        {tr('FRONTEND.HEADER.PROFILE', 'Profile')}
      </NavLink>
      <NavLink to={paths.security()} onClick={onClick} className={className}>
        {tr('FRONTEND.HEADER.SECURITY', 'Security')}
      </NavLink>
      {isOwnerOrAdministrator(customerRole) && (
        <NavLink to={paths.userManagement()} onClick={onClick} className={className}>
          {tr('FRONTEND.HEADER.USERS', 'Users')}
        </NavLink>
      )}
      <a
        href={settings.customerServiceUrl}
        rel='noopener noreferrer'
        target='_blank'
        onClick={onClick}
        className={className}>
        {tr('FRONTEND.HEADER.CUSTOMER_SERVICE', 'Customer Service')}
      </a>
      {isDevelopersMenuVisible() && (
        <NavLink to={paths.developer()} onClick={hideMenu} className={className}>
          {tr('FRONTEND.HEADER.DEVELOPERS', 'Developers')}
        </NavLink>
      )}
    </>
  )

  useEffect(() => {
    width > MAX_MOBILE_WIDTH && hideMenu()
    // eslint-disable-next-line
  }, [width])

  useEffect(() => {
    const pathname = location.pathname
    // noinspection DuplicatedCode
    const state =
      pathname !== paths.login() &&
      pathname !== paths.signUp() &&
      pathname !== paths.checkEmail() &&
      pathname !== paths.passwordReset() &&
      pathname !== paths.forgotPassword() &&
      pathname !== paths.changePasswordEmail() &&
      pathname !== paths.handlePasswordReset() &&
      pathname !== paths.handlePasswordResetInvited() &&
      pathname.substring(0, 9) !== '/invoice/'

    if (state !== visible) {
      setVisible(state)
    }
    // eslint-disable-next-line
  }, [location])

  useEffect(() => {
    const body = document.getElementsByTagName('body').item(0)
    if (body) {
      if (opened) {
        body.classList.add('menu-opened')
      } else {
        body.classList.remove('menu-opened')
      }
    }
    // eslint-disable-next-line
  }, [opened])

  return (
    <HeaderWrapper visible={visible}>
      {opened && <div className='overlay' onClick={hideMenu} />}

      <HeaderContainer maxMobileWidth={MAX_MOBILE_WIDTH}>
        <BrandLogo />

        {!isHamburgerVisible() && isLoggedIn() && (
          <HeaderInnerContainer>
            <MainMenuItems />
          </HeaderInnerContainer>
        )}

        {isHamburgerVisible() ? (
          <HeaderInnerContainer>
            <Hamburger>
              <HamburgerIcon onClick={() => setOpened(prevState => !prevState)}>
                <span className='menu-icon' />
              </HamburgerIcon>

              <HamburgerMenu open={opened} maxMobileWidth={MAX_MOBILE_WIDTH}>
                {isLoggedIn() && <MainMenuItems />}
                {isLoggedIn() && <UserMenuItems onClick={hideMenu} />}
                {isLoggedIn() && (
                  <NavLink to='#' onClick={handleLogoutClick}>
                    {tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
                  </NavLink>
                )}
                {!isLoggedIn() && (
                  <NavLink to={paths.login()} onClick={hideMenu}>
                    {tr('FRONTEND.HEADER.LOGIN', 'Log in')}
                  </NavLink>
                )}
                {!isLoggedIn() && (
                  <NavLink to={paths.signUp()} onClick={hideMenu}>
                    {tr('FRONTEND.HEADER.SIGNUP', 'Sign Up')}
                  </NavLink>
                )}
              </HamburgerMenu>
            </Hamburger>
          </HeaderInnerContainer>
        ) : (
          <HeaderInnerContainer>
            {isLoggedIn() && <UserDropDownMenu UserMenuItems={UserMenuItems} />}
          </HeaderInnerContainer>
        )}
      </HeaderContainer>
    </HeaderWrapper>
  )
}
