import { shallowEqual, useSelector } from 'react-redux'

import SumSubBanner from '../../onboarding/SumSubBanner'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { AccountSection, GraphSection, TransactionsSection } from '../elements'

export const DashboardAdmin = () => {
  const { aggregatedBalanceError } = useSelector(
    ({ user: { aggregatedBalanceError } }: any) => ({
      aggregatedBalanceError
    }),
    shallowEqual
  )

  return (
    <>
      <AccountSection />
      <ErrorDisplay error={aggregatedBalanceError} />

      <SumSubBanner className='dashboard' />

      <GraphSection />
      <TransactionsSection />
    </>
  )
}
