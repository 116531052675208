// noinspection DuplicatedCode

import { useSelector } from 'react-redux'

import { Preloader } from 'mmfintech-portal-commons'
import { AccountOverviewRow } from './AccountsOverviewRow'
import { AccountOverviewListWrapper } from './AccountsOverview.styled'

import { useUserPermissions } from '../../../hooks'

export const AccountsOverview = () => {
  const { isLoading, permissions } = useUserPermissions()

  const { paymentAccounts, paymentAccountsFetching } = useSelector(
    ({ banking: { paymentAccounts, paymentAccountsFetching } }: any) => ({
      paymentAccounts,
      paymentAccountsFetching
    })
  )

  if (paymentAccountsFetching || isLoading) {
    return <Preloader />
  }

  const accounts = paymentAccounts?.map((a: any) => {
    const permission = permissions?.find((perm: any) => a?.id === perm?.accountId)
    return { ...a, permission: permission?.accessLevel }
  })

  return (
    <AccountOverviewListWrapper>
      {accounts.map((account: any, i: number) => {
        return <AccountOverviewRow account={account} key={i} />
      })}
    </AccountOverviewListWrapper>
  )
}
