import { useState, useEffect, useContext, ChangeEvent, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'

import cn from 'classnames'

import { Button, ErrorDisplay, Spinner } from 'mmfintech-portal-commons'
import { Countdown, ResendMessage, VerificationContainer } from './Otp.styled'

import { actions, globalSettings } from 'mmfintech-backend-api'
import {
  tr,
  GlobalContext,
  OtpContext,
  useValidUntilTimer,
  useTimer,
  checkSingleValue,
  isValidFunction,
  formatPhoneNumber
} from 'mmfintech-commons'
import { LoginStatusEnum, TwoFactorTypeEnum } from 'mmfintech-commons-types'

type OtpProps = {
  onCancel?: () => void
}

// You can use OtpContext to set a function that will be executed after verifying the OTP:
// When triggering the OTP you need to do...

//   const { setOtpOnSuccess } = useContext(OtpContext)
//   setOtpOnSuccess(() => () => { //This should be executed when OTP is triggered
//      {/**what you want to happen on successful OTP */}
//   })

const getChallengeId = (challenge: { challengeId: number }) => {
  if (challenge?.challengeId) {
    return challenge.challengeId
  }
  console.error('Missing challengeId.')
  return null
}

export const OtpLegacy = ({ onCancel }: OtpProps) => {
  const [otp, setOtp] = useState('')
  const [attempts, setAttempts] = useState(0)

  const history = useHistory()
  const dispatch = useDispatch()

  const { otpOnSuccess, otpOnError, setOtpOnSuccess, setOtpOnError }: any = useContext(OtpContext)
  const { modalHide }: any = useContext(GlobalContext)

  const { userStatus, fetching, otpVerifyError, challenge } = useSelector(
    ({ user: { userStatus }, otp: { fetching, otpVerifyError, challenge } }: any) => ({
      userStatus,
      fetching,
      otpVerifyError,
      challenge
    }),
    shallowEqual
  )

  const { sentTo, twoFactorType, otpLength } = challenge || {}

  const codeSize = useMemo(() => (otpLength > 0 ? otpLength : globalSettings.otpCodeLength), [otpLength])

  const timer = useValidUntilTimer()
  const resendTimer = useTimer()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = checkSingleValue(event?.target?.value, { validation: 'numeric' })
    setOtp(newValue)
  }

  const cleanContextFunctions = () => {
    setOtpOnSuccess(null)
    setOtpOnError(null)
  }

  const handleSuccess = (props: any) => {
    isValidFunction(otpOnSuccess) && otpOnSuccess(props)
    if (!props?.data?.challenge) {
      cleanContextFunctions()
    }
  }

  const handleError = (props: any) => {
    isValidFunction(otpOnError) && otpOnError(props)
    if (!props?.data?.challenge) {
      setOtpOnError(null)
    }
  }

  const handleSubmit = () => {
    const challengeId = getChallengeId(challenge)
    setAttempts(attempts + 1)
    challengeId && dispatch(actions.otp.verify({ code: otp }, challengeId, history, handleSuccess, handleError))
  }

  const handleResend = () => {
    if (resendTimer.expired) {
      const challengeId = getChallengeId(challenge)
      challengeId && dispatch(actions.otp.reset(challengeId))
      resendTimer.start(30)
    }
  }

  const handleCancel = () => {
    dispatch(actions.otp.cleanup())
    typeof onCancel === 'function' && onCancel()
    modalHide()
  }
  useEffect(() => {
    if (otp.length === codeSize) {
      handleSubmit()
    }
    // eslint-disable-next-line
  }, [otp])

  const prepareTitle = () => {
    if (twoFactorType === TwoFactorTypeEnum.SMS) {
      if (userStatus === LoginStatusEnum.LOGGED_IN) {
        return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_SMS', 'SMS Verification')
      }

      return tr('FRONTEND.VERIFY_OTP.TITLE_SMS', 'SMS Confirmation')
    }

    if (userStatus === LoginStatusEnum.SECOND_FACTOR || userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_EMAIL', 'Email verification')
    }
    return tr('FRONTEND.VERIFY_OTP.TITLE_EMAIL', 'Email confirmation')
  }

  return (
    <VerificationContainer data-test='otp-verification-dialog'>
      <div className='title'>{prepareTitle()}</div>
      <div className='subtitle'>
        {tr('FRONTEND.VERIFY_OTP.TARGET_LABEL', "Please, enter the verification code that we've sent to:")}
        <span>{twoFactorType === TwoFactorTypeEnum.SMS ? formatPhoneNumber(sentTo) : sentTo}</span>
      </div>

      <ErrorDisplay error={otpVerifyError} />

      {fetching ? (
        <Spinner />
      ) : (
        <>
          <input
            id='otp'
            type='tel'
            className={`${cn({
              complete: otp?.length === codeSize,
              wrong: otp?.length === codeSize && otpVerifyError != null
            })} input`}
            autoComplete='off'
            onChange={e => handleChange(e)}
            value={otp}
            maxLength={codeSize}
            autoFocus
            placeholder={tr('FRONTEND.VERIFY_OTP.ENTER_CODE', 'Enter Code')}
            disabled={timer.expired || attempts >= globalSettings.otpMaxAttempts}
            data-test='tel-input'
          />

          <ResendMessage>
            {attempts < globalSettings.otpMaxAttempts ? (
              <>
                {tr('FRONTEND.VERIFY_OTP.NOT_RECEIVED', "Haven't received it?")}
                <span onClick={handleResend} className={cn({ disabled: !resendTimer.expired })}>
                  {resendTimer.expired
                    ? tr('FRONTEND.VERIFY_OTP.RESEND_CODE', 'Re-send code')
                    : resendTimer.remainingTime}
                </span>
              </>
            ) : (
              tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
            )}
          </ResendMessage>

          <div className='buttons-wrap'>
            {/* <Button type='button' color='secondary' text='Submit' disabled={otp.length !== codeSize} onClick={handleSubmit} /> */}

            <Button
              type='button'
              color='secondary'
              text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
              disabled={fetching}
              onClick={handleCancel}
              data-test='button-cancel'
            />
          </div>
        </>
      )}

      {attempts >= globalSettings.otpMaxAttempts || fetching ? null : (
        <Countdown isExpired={timer.expired}>{timer.formattedTime}</Countdown>
      )}
    </VerificationContainer>
  )
}
