import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { paths } from 'mmfintech-backend-api'
import { Button } from 'mmfintech-portal-commons'
import { GlobalContext, tr } from 'mmfintech-commons'

import JumpingButton from '../../../components/JumpingButton'

import SendIcon from '../../../images/icons/welcome-send.svg?react'
import DepositIcon from '../../../images/icons/welcome-deposit.svg?react'
import ExchangeIcon from '../../../images/icons/welcome-exchange.svg?react'

const WelcomeModal = () => {
  const { modalHide } = useContext(GlobalContext)

  const history = useHistory()

  const handleDepositClick = () => {
    history.push(paths.banking.deposit())
    modalHide()
  }

  const handleSendMoneyClick = () => {
    history.push(paths.banking.send())
    modalHide()
  }

  const handleExchangeClick = () => {
    history.push(paths.banking.exchange())
    modalHide()
  }

  return (
    <>
      <ModalContainer>
        <WelcomeModalWrapper data-test='welcome-modal'>
          <div className='welcome-modal-content'>
            <h1 className='title'>{tr('FRONTEND.WELCOME.MODAL.HEADING', 'Welcome to Bank of Central!')}</h1>

            <div className='welcome-text'>
              {tr('FRONTEND.WELCOME.MODAL.COMPLETE.TEXT', 'Complete your account to get access to all features!')}
            </div>

            <ButtonWrapper>
              <JumpingButton
                icon={<DepositIcon />}
                label={tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
                onClick={handleDepositClick}
                data-test='button-deposit'
              />
              <JumpingButton
                icon={<ExchangeIcon />}
                label={tr('FRONTEND.DASHBOARD.BUTTONS.EXCHANGE', 'Exchange')}
                onClick={handleExchangeClick}
                data-test='exchange-button'
              />
              <JumpingButton
                icon={<SendIcon />}
                label={tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
                onClick={handleSendMoneyClick}
                data-test='send-money-button'
              />
            </ButtonWrapper>

            <div className='buttons-wrapper'>
              <Button
                text={tr('FRONTEND.WELCOME.MODAL.GET.STARTED.BUTTON', 'Get Started')}
                color='primary'
                onClick={() => {
                  modalHide()
                }}
              />
            </div>
          </div>
        </WelcomeModalWrapper>
      </ModalContainer>
    </>
  )
}

export default WelcomeModal

const ModalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
`

const WelcomeModalWrapper = styled.div`
  width: 100%;
  max-width: 64rem;
  padding: 4rem;

  border-radius: 1rem;
  background-color: #ffffff;
  .welcome-modal-content {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    text-align: center;
    .title {
      font-size: 2.4rem;
      font-weight: 400;
    }
    .welcome-text {
      padding: 1rem 0;
      font-size: 1.4rem;

      &.italic {
        font-style: italic;
      }
    }
    .buttons-wrapper {
      margin-top: 3rem;
      button:nth-of-type(2) {
        margin-top: 1rem;
      }
    }
  }
  @media (max-width: 480px) {
    margin: auto;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  margin: 2rem 0;
  .round-square-container {
    margin: 0 0.5rem;
  }
  @media (max-width: 480px) {
    flex-flow: column nowrap;
    div:nth-of-type(2) {
      margin: 1rem 0;
    }
  }
`
