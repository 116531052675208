import cn from 'classnames'

import settings from '../../settings'
import { LogoImage } from '../../icons'
import { LogoWrapper } from './Logo.styled'

export interface LogoProps {
  autoWidth?: boolean
}

export const Logo = ({ autoWidth }: LogoProps) => (
  <LogoWrapper className={cn({ 'auto-width': autoWidth })}>
    <a href={settings.landingPageUrl}>
      <LogoImage />
    </a>
  </LogoWrapper>
)
