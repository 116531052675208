// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const AccountOverviewRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 5rem 1fr 1fr 1fr 1fr;
  gap: 1rem;
  font-size: 1.4rem;
  background: #ffffff;

  border-radius: 4px;
  padding: 1.5rem;
  margin-bottom: 1rem;

  .cell {
    display: grid;

    .title {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #85a0ad;

      @media (min-width: 500px) {
        font-size: 1.2rem;
      }
    }
    .subtitle {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.7rem;

      @media (min-width: 500px) {
        font-size: 1.4rem;
      }
    }
  }
`
export const AccountOverviewListWrapper = styled.div`
  margin-bottom: 4rem;
`
