import { useHistory } from 'react-router-dom'

import SpendingLimitBarUser from '../../requests/SpendingLimitBarUser'

import { Button } from 'mmfintech-portal-commons'
import { AccountsOverview } from '../../account'
import { TransactionsSection } from '../elements'
import { StaffDashboardNavigation, StaffDashboardWrapper } from './DashboardStaff.styled'

import { tr } from 'mmfintech-commons'
import { actions, paths } from 'mmfintech-backend-api'
import { useHasWritePermissions } from '../../../hooks'

import SendIcon from '../../../images/icons/send.svg?react'

export const DashboardStaff = () => {
  const history = useHistory()
  const hasAnyPermissions = useHasWritePermissions()

  const handleSendMoneyClick = () => {
    actions.routing.setWithdrawOrigin('')
    history.push(paths.banking.send())
  }

  return (
    <StaffDashboardWrapper>
      <StaffDashboardNavigation>
        <SpendingLimitBarUser />

        {hasAnyPermissions && (
          <Button
            type='button'
            color='secondary'
            icon={<SendIcon />}
            text={tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
            onClick={handleSendMoneyClick}
            data-test='send-money-button'
          />
        )}
      </StaffDashboardNavigation>

      <AccountsOverview />
      <TransactionsSection />
    </StaffDashboardWrapper>
  )
}
