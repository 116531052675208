export const LogoImage = ({ color = 'white', height = '56px' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 433.72 194.4' height={height}>
    <path
      d='M49.82,41.72c5.25,1.41,9.3,4.13,12.16,8.17,2.86,4.03,4.29,8.75,4.29,14.16,0,7.05-2.47,13.04-7.4,17.98-5.01,4.86-11.67,7.28-19.98,7.28H.23V2.47h36.9c7.76,0,13.79,2.16,18.1,6.46,4.31,4.31,6.46,9.36,6.46,15.16,0,4-1.1,7.62-3.29,10.87-2.19,3.25-5.05,5.5-8.58,6.76ZM34.54,16.69h-18.45v20.09h17.86c3.6,0,6.44-.98,8.52-2.94,2.08-1.96,3.11-4.43,3.11-7.4,0-2.74-1-5.05-3-6.93-2-1.88-4.68-2.82-8.05-2.82ZM16.09,74.97h19.74c4.23,0,7.6-1.17,10.11-3.52s3.76-5.21,3.76-8.58c0-3.6-1.23-6.58-3.7-8.93-2.47-2.35-5.86-3.53-10.16-3.53h-19.74v24.56Z'
      fill={color}
      strokeWidth={0}
    />
    <path
      d='M127.72,33.61v-8.11h14.57v63.81h-14.57v-7.87c-5.56,6.27-12.5,9.4-20.8,9.4-9.17,0-16.73-3.21-22.68-9.63-5.95-6.42-8.93-14.45-8.93-24.09s2.98-17.55,8.93-23.74c6.03-6.27,13.59-9.4,22.68-9.4,8.15,0,15.08,3.21,20.8,9.64ZM128.08,57.34c0-5.41-1.76-9.99-5.29-13.75-3.53-3.76-8.03-5.64-13.51-5.64s-9.99,1.88-13.52,5.64c-3.45,3.84-5.17,8.42-5.17,13.75s1.72,10.03,5.17,13.87c3.53,3.76,8.03,5.64,13.52,5.64s9.99-1.88,13.51-5.64c3.52-3.76,5.29-8.38,5.29-13.87Z'
      fill={color}
      strokeWidth={0}
    />
    <path
      d='M173.2,89.31h-15.28V25.5h15.04v8.93c1.72-3.29,4.35-5.88,7.87-7.76,3.52-1.88,7.6-2.82,12.22-2.82,6.97,0,12.89,2.31,17.74,6.93,4.86,4.62,7.29,11.16,7.29,19.62v38.9h-15.39v-34.67c0-5.25-1.25-9.36-3.76-12.34-2.51-2.98-5.92-4.46-10.22-4.46-4.54,0-8.27,1.59-11.16,4.76s-4.35,7.27-4.35,12.28v34.43Z'
      fill={color}
      strokeWidth={0}
    />
    <path
      d='M293.65,89.31h-19.04l-21.86-27.85-4.7,4.23v23.62h-15.28V0h15.28v48.3l24.56-22.8h19.51l-28.79,26.32,30.32,37.49Z'
      fill={color}
      strokeWidth={0}
    />
    <path
      d='M44.65,194.17c-12.69,0-23.31-4.11-31.85-12.34-8.54-8.3-12.81-19-12.81-32.08s4.35-23.97,13.04-32.43c8.77-8.54,19.39-12.81,31.85-12.81,7.68,0,14.87,1.65,21.56,4.94,6.7,3.29,11.97,7.72,15.8,13.28l-10.93,10.46c-7.05-8.77-15.63-13.16-25.74-13.16-8.15,0-15.04,2.82-20.68,8.46-5.56,5.72-8.34,12.81-8.34,21.27s2.74,15.32,8.23,20.8c5.48,5.49,12.38,8.23,20.68,8.23,10.65,0,19.39-4.39,26.2-13.16l11.16,10.11c-4.15,5.56-9.6,10.03-16.33,13.4-6.74,3.37-14.02,5.05-21.86,5.05Z'
      fill={color}
      strokeWidth={0}
    />
    <path
      d='M153.82,159.62c0,2.04-.16,4.31-.47,6.82h-48.65c1.02,4.54,3.19,8.13,6.52,10.75,3.33,2.63,7.66,3.94,12.99,3.94,7.76,0,14.76-2.19,21.03-6.58l6.11,10.93c-7.91,5.88-17.16,8.81-27.73,8.81-5.8,0-10.95-.94-15.45-2.82-4.51-1.88-8.13-4.41-10.87-7.58-2.74-3.17-4.8-6.7-6.17-10.58-1.37-3.88-2.06-8.01-2.06-12.4,0-9.71,3.06-17.67,9.17-23.86,6.19-6.35,14.18-9.52,23.97-9.52,9.17,0,16.73,2.9,22.68,8.7,5.95,5.72,8.93,13.51,8.93,23.38ZM122.21,140.35c-4.47,0-8.25,1.29-11.34,3.88s-5.15,6.11-6.17,10.58h34.08c-.63-4.46-2.45-7.99-5.46-10.58s-6.72-3.88-11.1-3.88Z'
      fill={color}
      strokeWidth={0}
    />
    <path
      d='M180.96,192.87h-15.28v-63.81h15.04v8.93c1.72-3.29,4.35-5.88,7.87-7.76,3.52-1.88,7.6-2.82,12.22-2.82,6.97,0,12.89,2.31,17.74,6.93,4.86,4.62,7.29,11.16,7.29,19.62v38.9h-15.39v-34.67c0-5.25-1.25-9.36-3.76-12.34-2.51-2.98-5.92-4.46-10.22-4.46-4.54,0-8.27,1.59-11.16,4.76s-4.35,7.27-4.35,12.28v34.43Z'
      fill={color}
      strokeWidth={0}
    />
    <path
      d='M280.14,177.13l4.82,11.99c-5.01,3.52-11.16,5.29-18.45,5.29-14.57,0-21.86-8.3-21.86-24.91v-27.5h-10.58v-12.93h10.81v-22.56h15.16v22.56h21.39v12.93h-21.39v26.91c0,7.68,3.09,11.52,9.28,11.52,3.6,0,7.21-1.1,10.81-3.29Z'
      fill={color}
      strokeWidth={0}
    />
    <path
      d='M308.34,192.87h-15.28v-63.81h14.69v8.81c3.92-6.97,9.83-10.46,17.74-10.46,2.66,0,5.21.43,7.64,1.29l-1.29,14.69c-2.74-.86-5.21-1.29-7.4-1.29-4.94,0-8.85,1.67-11.75,4.99-2.9,3.33-4.35,8.21-4.35,14.63v31.14Z'
      fill={color}
      strokeWidth={0}
    />
    <path
      d='M388.25,137.17v-8.11h14.57v63.81h-14.57v-7.87c-5.56,6.27-12.5,9.4-20.8,9.4-9.17,0-16.73-3.21-22.68-9.63-5.95-6.42-8.93-14.45-8.93-24.09s2.98-17.55,8.93-23.74c6.03-6.27,13.59-9.4,22.68-9.4,8.15,0,15.08,3.21,20.8,9.64ZM388.6,160.91c0-5.41-1.76-9.99-5.29-13.75-3.53-3.76-8.03-5.64-13.51-5.64s-9.99,1.88-13.52,5.64c-3.45,3.84-5.17,8.42-5.17,13.75s1.72,10.03,5.17,13.87c3.53,3.76,8.03,5.64,13.52,5.64s9.99-1.88,13.51-5.64c3.52-3.76,5.29-8.38,5.29-13.87Z'
      fill={color}
      strokeWidth={0}
    />
    <path d='M433.72,103.57v89.31h-15.28v-89.31h15.28Z' fill={color} strokeWidth={0} />
    <path
      d='M368.53,51.59c0,.62-.03,1.28-.09,1.98-.06.71-.14,1.4-.23,2.07-.09.68-.17,1.31-.23,1.89-.06.59-.12,1.03-.19,1.34l-1.29,7.37c-1.29,7.43-4.01,12.9-8.15,16.4-4.15,3.5-9.69,5.25-16.63,5.25-6.39,0-11.07-1.5-14.05-4.51-2.98-3.01-4.47-7.13-4.47-12.35,0-2.82.18-5.34.55-7.55l1.29-7.37c1.29-7.49,4.04-12.95,8.25-16.36,4.21-3.41,9.72-5.11,16.54-5.11,6.14,0,10.8,1.4,13.96,4.19,3.16,2.8,4.75,7.05,4.75,12.76ZM360.24,51.59c0-1.47-.17-2.81-.51-4.01s-.94-2.21-1.8-3.04-2-1.48-3.41-1.94-3.22-.69-5.44-.69c-2.52,0-4.67.31-6.45.92-1.78.62-3.3,1.55-4.56,2.81-1.26,1.26-2.29,2.87-3.09,4.84-.8,1.97-1.44,4.33-1.93,7.1l-1.29,7.37c-.19,1.05-.32,2.03-.42,2.95-.09.92-.14,1.97-.14,3.13,0,3.32.83,5.74,2.49,7.28,1.66,1.54,4.51,2.3,8.57,2.3,2.58,0,4.77-.31,6.59-.92,1.81-.61,3.35-1.55,4.61-2.81s2.27-2.87,3.04-4.84c.77-1.97,1.4-4.33,1.89-7.1l1.29-7.37c.25-1.35.4-2.46.46-3.32.06-.86.09-1.75.09-2.67Z'
      fill={color}
      strokeWidth={0}
    />
    <path
      d='M413.95,21c-1.17-.06-2.5-.14-4.01-.23-1.51-.09-3.06-.14-4.65-.14-1.41,0-2.55.18-3.41.55-.86.37-1.55.92-2.07,1.66-.52.74-.94,1.69-1.24,2.86-.31,1.17-.59,2.55-.83,4.15l-1.11,6.08h14.38l-1.2,6.91h-14.38l-8.75,49.85c-.49,2.7-1.12,4.98-1.89,6.82s-1.75,3.32-2.95,4.42c-1.2,1.11-2.66,1.9-4.38,2.4-1.72.49-3.78.74-6.17.74-1.17,0-2.36-.05-3.59-.14-1.23-.09-2.03-.2-2.4-.32l1.11-6.08c.55,0,1.31.01,2.26.05.95.03,1.98.05,3.09.05s2.03-.12,2.76-.37c.74-.25,1.34-.68,1.8-1.29.46-.62.84-1.43,1.15-2.44.31-1.01.61-2.29.92-3.83l8.85-49.85-9.49-.74,1.01-6.17h9.68l1.29-6.91c.37-2.03.81-3.95,1.34-5.76.52-1.81,1.34-3.39,2.44-4.75,1.11-1.35,2.58-2.43,4.42-3.22,1.84-.8,4.3-1.2,7.37-1.2,1.97,0,3.78.14,5.44.42s3.07.57,4.24.87l-1.01,5.62Z'
      fill={color}
      strokeWidth={0}
    />
  </svg>
)
