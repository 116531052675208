import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import cn from 'classnames'

import { ModalContainer, ModalWrapper } from './ModalDialog.styled'

import { useModal } from 'mmfintech-commons'

export interface ModalDialogProps {
  content?: ReactNode
  options?: any
  visible: boolean
  onClose: () => void
}
//   options: PropTypes.shape({
//   closeIconPosition: PropTypes.oneOf(['default']),
//   closeOnClickOutside: PropTypes.bool,
//   closeOnEscape: PropTypes.bool,
//   hideCloseIcon: PropTypes.bool,
//   scrollable: PropTypes.bool,
//   maxWidth: PropTypes.number,
//   onClose: PropTypes.func,
//
//   transparent: PropTypes.bool,
//   size: PropTypes.oneOf(['auto', 'small', 'medium', 'large', 'extra-large', 'horizontal', 'centered'])
// }),

export const ModalDialog = ({ content, options, visible, onClose }: ModalDialogProps) => {
  const { ref, modalOptions, handleMouseDown } = useModal({
    options,
    visible,
    onClose
  })

  const location = useLocation()

  useEffect(() => {
    if (visible) {
      typeof onClose === 'function' && onClose()
    }
    // eslint-disable-next-line
  }, [location.pathname])

  if (!visible) {
    return null
  }

  return (
    <ModalWrapper onMouseDown={handleMouseDown} ref={ref}>
      <ModalContainer
        data-test='modal-container'
        onMouseDown={e => e.stopPropagation()}
        className={cn({
          'size-auto': modalOptions.size === 'auto',
          'size-large': modalOptions.size === 'large',
          'size-small': modalOptions.size === 'small',
          'size-extra-large': modalOptions.size === 'extra-large',
          centered: modalOptions.size === 'centered',
          horizontal: modalOptions.size === 'horizontal',
          transparent: modalOptions.transparent === true,
          'overflow-none': modalOptions.overflow === 'none'
        })}>
        {content}
      </ModalContainer>
    </ModalWrapper>
  )
}
