import React from 'react'

import { DateSectionWrapper } from './styled/activityDateLine.styled'
import { translateMonthName } from 'mmfintech-commons'

const ActivityDateLine = ({ datum }) => {
  const dt = new Date(datum)
  const day = dt.getDate()
  const year = dt.getFullYear()
  const month = translateMonthName(dt.getMonth() + 1)

  return (
    !isNaN(dt.getDate()) && (
      <DateSectionWrapper className='date-section'>
        <span className='grid-line left' />
        <span className='date-wrap'>
          <span className='day'>{day}</span>
          <span className='year'>
            {month}, {year}
          </span>
        </span>
        <span className='grid-line right' />
      </DateSectionWrapper>
    )
  )
}

export default ActivityDateLine
