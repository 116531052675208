import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(320px, 1490px) 1fr;
  grid-template-rows: 9rem auto;
  grid-template-areas: 'header header header' 'left-column main-content right-column';
  grid-row-gap: 0;

  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  font-family: Inter, sans-serif;
  background-color: #f4f6f6;

  & > div:first-of-type {
    animation: fadein 0.75s;
  }

  .fluid & {
    grid-row-gap: 0;

    @media (min-width: 900px) {
      grid-row-gap: 0;
    }

    @media (min-width: 1175px) {
      grid-template-rows: 9rem auto;
      grid-row-gap: 0;
    }
  }

  .round-square-container {
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
    color: #000000;
    padding: 3rem 5rem;

    @media (max-width: 1024px) {
      padding: 2rem 3rem;
    }
  }

  .label-image-link {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;

    color: #a3b8c2;
    font-family: inherit;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.7rem;
    cursor: pointer;

    .icon,
    img,
    svg {
      margin-right: 0.8rem;
    }
  }

  .input-hint {
    color: #a3b8c2;
    font-size: 1.2rem;
    font-style: italic;
    margin: -0.5rem 0 2rem;
  }

  ${'' /* react-datepicker styling */}
  .react-datepicker {
    background-color: #ffffff;
    border-color: rgba(163, 184, 194, 0.15);
    border-radius: 6px;
    box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
    font-family: inherit;
    font-size: 1.2rem;
    padding: 0 0 1rem;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__header {
    background-color: rgba(163, 184, 194, 0.1);
    border-bottom-color: rgba(163, 184, 194, 0.15);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 1rem 1rem 0;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: #000000;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 3rem;
  }
  .react-datepicker__day-names {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .react-datepicker__day-name {
    color: #000000;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: normal;
    margin: 2px 4px;
    width: 3rem;
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  }
  .react-datepicker__navigation--next {
    top: 1rem;
    right: 1rem;
  }
  .react-datepicker__navigation--previous {
    top: 1rem;
    left: 1rem;
  }
  .react-datepicker__day {
    border: 1px solid transparent;
    border-radius: 4px;
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 3rem;
    margin: 2px 4px;
    width: 3rem;
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    border-top-color: rgba(163, 184, 194, 0.15);
  }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
    border-top-color: #ffffff;
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: rgba(163, 184, 194, 0.15);
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: #ffffff;
  }
  .react-datepicker__navigation-icon::before {
    border-color: #000000;
    border-top-width: 1px;
    border-right-width: 1px;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    background-color: #000000;
    border: 1px solid #000000;
    color: #ffffff;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover,
  .react-datepicker__month--selected:hover,
  .react-datepicker__month--in-selecting-range:hover,
  .react-datepicker__month--in-range:hover,
  .react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter--in-selecting-range:hover,
  .react-datepicker__quarter--in-range:hover {
    background-color: transparent;
  }
  .react-datepicker__day--outside-month {
    color: #939ea7;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    color: #000000;
    background-color: transparent;
    border: 1px solid #85a0ad;
  }

  .trs-2 {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  #info-tooltip {
    color: #ffffff !important;
    background: #2f3032 !important;
  }
`
