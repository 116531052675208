import React from 'react'
import { useDispatch } from 'react-redux'

import parse from 'html-react-parser'
import DomPurify from 'dompurify'

import { ActivityWrap } from './styled/activityInfoLine.styled'

import { actions, paths } from 'mmfintech-backend-api'
import { transformMerchantActivity, useActivities } from 'mmfintech-backend-api'

import RightArrow from '../../../images/icons/arrow-right-white-small.svg?react'

const ActivityInfoLine = ({ activity }) => {
  const dispatch = useDispatch()

  const { handleActivityClick } = useActivities({
    securityPath: paths.security(),
    handleInvoicePreview: invoiceId => {
      dispatch(actions.invoice.findById(invoiceId))
      dispatch(actions.invoice.fetchInvoicePreview(invoiceId))
    }
  })

  const data = transformMerchantActivity(activity)
  const fixTime = i => (i < 10 ? '0' + i : i)

  const dt = new Date(activity?.time)
  const h = fixTime(dt.getHours())
  const m = fixTime(dt.getMinutes())
  const s = fixTime(dt.getSeconds())

  return (
    <ActivityWrap className='activity-section' onClick={() => handleActivityClick(data)}>
      <span className='left-side'>
        <span className='time'>
          {h}:{m}:{s}
        </span>
        <span className='description'>{parse(DomPurify.sanitize(activity?.text))}</span>
      </span>
      <span className='arrow'>
        <RightArrow />
      </span>
    </ActivityWrap>
  )
}

export default ActivityInfoLine
