import { configuration, initialize } from 'mmfintech-backend-api'

initialize(import.meta.env)

const settings = {
  cookieDomain: configuration.isLocal() ? null : '.bankofcentral.com',
  customerServiceUrl: 'mailto:team@bankofcentral.com',
  landingPageUrl: 'https://bankofcentral.com',
  languages: ['en'],

  defaultUploadInvoiceAcceptType: ['pdf', 'jpg', 'jpeg', 'png'],
  defaultUploadInvoiceMaxFileSize: 1024 * 1024,
  logoAcceptType: ['jpg', 'jpeg', 'gif', 'png', 'svg'],
  logoMaxFileSize: 1024 * 1024,
  questionnaireFileTypes: ['pdf', 'jpg', 'jpeg', 'png'],
  questionnaireMaxFileSize: 1024 * 1024 * 10
}

export default settings
