import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Button } from 'mmfintech-portal-commons'
import { Callout, UnlockedFeatureModal } from '../../../../components'
import { AccountSectionWrapper, BalanceContainer } from './AccountSection.styled'

import { hints } from '../../../../constants'
import { useHasWritePermissions } from '../../../../hooks'
import { actions, isOwnerOrAdministrator, paths } from 'mmfintech-backend-api'
import { extractCurrencyCode, formatMoney, GlobalContext, isValidArray, tr } from 'mmfintech-commons'

import DepositIcon from '../../../../images/icons/deposit.svg?react'
import SendIcon from '../../../../images/icons/send.svg?react'
import ExchangeIcon from '../../../../images/icons/exchange.svg?react'
import RedDotIcon from '../../../../images/icons/new-dot-icon.svg?react'

const {
  unlocked: { deposit, exchange }
} = hints

export const AccountSection = () => {
  const { modalShow } = useContext(GlobalContext)

  const {
    aggregatedBalance,
    customerRole,
    hints: seenHints
  } = useSelector(
    ({ user: { aggregatedBalance, customerRole, hints } }: any) => ({
      aggregatedBalance,
      customerRole,
      hints
    }),
    shallowEqual
  )

  const history = useHistory()
  const dispatch = useDispatch()

  const hasAnyPermissions = useHasWritePermissions()

  const handleDepositClick = () => {
    actions.routing.setDepositOrigin('')
    history.push(paths.banking.deposit())
  }

  const handleSendMoneyClick = () => {
    actions.routing.setWithdrawOrigin('')
    history.push(paths.banking.send())
  }

  const handleExchangeClick = () => {
    actions.routing.setExchangeOrigin('')
    history.push(paths.banking.exchange())
  }

  return (
    <AccountSectionWrapper>
      <BalanceContainer>
        {isValidArray(aggregatedBalance) && (
          <>
            <div className='label'>{tr('FRONTEND.DASHBOARD.ACCOUNT_SELECT.TOTAL_LABEL', 'Total account value')}</div>
            <div data-test='balance'>
              {formatMoney(
                aggregatedBalance[aggregatedBalance.length - 1].amount,
                extractCurrencyCode(aggregatedBalance[aggregatedBalance.length - 1])
              )}
            </div>
          </>
        )}
      </BalanceContainer>

      <div className='buttons-container'>
        {isOwnerOrAdministrator(customerRole) && (
          <Button
            type='button'
            color='secondary'
            icon={<DepositIcon />}
            text={
              <Callout hintKey={deposit} content={<RedDotIcon />} isOpen={true}>
                {tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
              </Callout>
            }
            onClick={async () => {
              handleDepositClick()
              if (seenHints && !seenHints?.find((hint: any) => hint?.hintKey === 'unlocked-deposits')?.isSeen) {
                dispatch(actions.merchant.setUserHints({ hintKey: deposit }, seenHints))
                modalShow({
                  content: <UnlockedFeatureModal type='deposit' />
                })
              }
            }}
            data-test='deposit-button'
            data-for='button-tooltip'
          />
        )}
        {hasAnyPermissions && (
          <Button
            type='button'
            color='secondary'
            icon={<SendIcon />}
            text={tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
            onClick={handleSendMoneyClick}
            data-test='send-money-button'
          />
        )}
        {isOwnerOrAdministrator(customerRole) && (
          <Button
            type='button'
            color='secondary'
            icon={<ExchangeIcon />}
            text={
              <Callout hintKey={exchange} content={<RedDotIcon />} isOpen={true}>
                {tr('FRONTEND.DASHBOARD.BUTTONS.EXCHANGE', 'Exchange')}
              </Callout>
            }
            onClick={async () => {
              handleExchangeClick()
              if (seenHints && !seenHints?.find((hint: any) => hint?.hintKey === 'unlocked-exchange')?.isSeen) {
                dispatch(actions.merchant.setUserHints({ hintKey: exchange }, seenHints))
                modalShow({
                  content: <UnlockedFeatureModal type='exchange' />
                })
              }
            }}
            data-test='exchange-button'
            data-for='button-tooltip'
          />
        )}
      </div>
    </AccountSectionWrapper>
  )
}
