// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const AccountSectionWrapper = styled.div`
  width: 100%;
  margin-bottom: 3rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  @media (min-width: 768px) {
    flex-flow: row nowrap;
  }

  .buttons-container {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
    width: 100%;

    flex: 0;
    @media (min-width: 768px) {
      flex-flow: row nowrap;
    }

    .button {
      height: 46px;
      border-radius: 4px;
      box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

      color: #85a0ad;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7rem;
      text-transform: capitalize;
      padding: 0 3rem;
    }

    #button-tooltip {
      border-radius: 8px;

      font-family: 'Inter', sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;

      * {
        text-align: left;
      }
    }
  }
`

export const BalanceContainer = styled.div`
  flex: 1;
  align-self: flex-start;
  display: flex;
  flex-flow: column nowrap;

  color: #000000;
  font-family: inherit;
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 3.9rem;

  .label {
    color: #85a0ad;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2rem;
    text-transform: uppercase;
  }
`
