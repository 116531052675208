import { useContext, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ErrorDisplay } from 'mmfintech-portal-commons'

import WelcomeModal from './elements/WelcomeModal'
import ActivityDateLine from './elements/ActivityDateLine'
import ActivityInfoLine from './elements/ActivityInfoLine'

import { DashboardAdmin } from './DashboardAdmin'
import { DashboardStaff } from './DashboardStaff'
import {
  DashboardLeft,
  DashboardRight,
  DashboardRightTitle,
  DashboardTitle,
  DashboardWrapper
} from './Dashboard.styled'

import { actions } from 'mmfintech-backend-api'
import { GlobalContext, isValidArray, tr } from 'mmfintech-commons'

import { CustomerRoleEnum } from 'mmfintech-commons-types'

const Dashboard = () => {
  const { modalShow } = useContext(GlobalContext)

  const {
    customerRole,
    lastActivities,
    lastActivitiesError,
    lastActivitiesFetching,
    selectedLanguage,
    initialEmailVerification
  } = useSelector(
    ({
      user: {
        customerRole,
        lastActivities,
        lastActivitiesError,
        lastActivitiesFetching,
        selectedLanguage,
        initialEmailVerification
      }
    }: any) => ({
      customerRole,
      lastActivities,
      lastActivitiesError,
      lastActivitiesFetching,
      selectedLanguage,
      initialEmailVerification
    }),
    shallowEqual
  )

  const [activities, setActivities] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.kyb.getSumsubOnboardingStatus())
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    dispatch(actions.merchant.fetchLastActivities())
    // eslint-disable-next-line
  }, [dispatch, selectedLanguage])

  useEffect(() => {
    const list = []
    if (isValidArray(lastActivities)) {
      lastActivities?.forEach(item => {
        const dt = new Date(item.time).toDateString()
        let innerList = list.find(v => v.date === dt)
        if (innerList == null) {
          innerList = { date: dt, list: [item] }
          list.push(innerList)
        } else {
          innerList.list.push(item)
        }
      })
    }
    setActivities(list)
  }, [lastActivities])

  useEffect(() => {
    if (initialEmailVerification) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: <WelcomeModal />
      })
      dispatch(actions.auth.verifyEmailCleanup())
    }
    // eslint-disable-next-line
  }, [initialEmailVerification])

  return (
    <DashboardWrapper>
      <DashboardLeft>
        <DashboardTitle className='title'>{tr('FRONTEND.DASHBOARD.TITLE', 'Overview')}</DashboardTitle>
        {customerRole === CustomerRoleEnum.STAFF ? <DashboardStaff /> : <DashboardAdmin />}
      </DashboardLeft>

      <DashboardRight>
        <DashboardRightTitle>{tr('FRONTEND.DASHBOARD.ACTIVITY_TITLE', 'Activity')}</DashboardRightTitle>

        {lastActivitiesFetching ? null : lastActivitiesError ? (
          <ErrorDisplay error={lastActivitiesError} />
        ) : isValidArray(activities) ? (
          activities?.map((item, index) => (
            <span className='line-wrap' key={'dt-' + index}>
              <ActivityDateLine datum={item.date} />
              {item.list.map((activity, index2) => (
                <ActivityInfoLine key={'act-' + index + '-' + index2} activity={activity} />
              ))}
            </span>
          ))
        ) : null}
      </DashboardRight>
    </DashboardWrapper>
  )
}

export default Dashboard
