// noinspection JSCheckFunctionSignatures

import React from 'react'

import Login from './views/auth/Login'
import Logout from './views/auth/Logout'
import Dashboard from './views/dashboard/Dashboard'

import { paths } from 'mmfintech-backend-api'

const Exchange = React.lazy(() => import('./views/banking/Exchange'))
const SendMoney = React.lazy(() => import('./views/banking/SendMoney'))
const Transactions = React.lazy(() => import('./views/transactions/Transactions'))
const TransactionFinalStatus = React.lazy(() => import('./views/static/TransactionFinalStatus'))
const VerificationSumSub = React.lazy(() => import('./views/onboarding/SumSubVerification'))

// prettier-ignore
const routes = [
  { path: paths.root(), exact: true, redirect: paths.dashboard() },
  { path: paths.login(), component: Login },
  { path: paths.logout(), component: Logout },
  { path: paths.signUp(), component: React.lazy(() => import('./views/auth/Signup')) },
  { path: paths.checkEmail(), component: React.lazy(() => import('./views/auth/VerifyEmailMessage')) },
  { path: paths.forgotPassword(), component: React.lazy(() => import('./views/auth/ForgotPassword')) },
  { path: paths.changePasswordEmail(), component: React.lazy(() => import('./views/auth/ForgottenPasswordEmail')) },
  { path: paths.passwordReset(), component: React.lazy(() => import('./views/static/PasswordResetMessage')) },
  { path: paths.handlePasswordReset(), component: React.lazy(() => import('./views/auth/ResetPassword')) },
  { path: paths.handleEmailVerify(), component: React.lazy(() => import('./views/auth/VerifyEmail')) },
  { path: paths.handleInvitationVerify(), component: React.lazy(() => import('./views/auth/VerifyEmail')) },
  { path: paths.handlePasswordResetInvited(), component: React.lazy(() => import('./views/auth/SetPassword')) },

  { path: paths.dashboard(), component: Dashboard },
  { path: paths.profile(), component: React.lazy(() => import('./views/settings/Profile')) },
  { path: paths.security(), component: React.lazy(() => import('./views/settings/Security')) },
  { path: paths.userManagement(), component: React.lazy(() => import('./views/users')) },

  { path: paths.banking.depositPreview(), exact: true, component: React.lazy(() => import('./views/banking/ApplicationFeeDepositPreview')) },
  { path: paths.banking.deposit(), component: React.lazy(() => import('./views/banking/Deposit')) },
  { path: paths.banking.exchangeFromTo(':fromCurrency', ':toCurrency'), component: Exchange },
  { path: paths.banking.exchange(), component: Exchange },
  { path: paths.banking.sendBankTransfer(), exact: true, component: React.lazy(() => import('./views/banking/BankTransfer')) },
  { path: paths.banking.sendCrypto(), exact: true, component: React.lazy(() => import('./views/banking/CryptoTransfer')) },
  { path: paths.banking.sendTransfer(), exact: true, component: React.lazy(() => import('./views/banking/InternalTransfer')) },
  { path: paths.banking.sendIntraAccountTransfer(), exact: true, component: React.lazy(() => import('./views/banking/IntraAccountTransfer')) },
  { path: paths.banking.sendDigitalWallet(), exact: true, component: React.lazy(() => import('./views/banking/WalletTransfer')) },
  { path: paths.banking.sendTo(':recipientId'), component: SendMoney },
  { path: paths.banking.send(), component: SendMoney },
  { path: paths.banking.expenses(), component: React.lazy(() => import('./views/requests/Requests')) },

  { path: paths.banking.accounts.list(), component: React.lazy(() => import('./views/account/Accounts')) },
  { path: paths.banking.transactions.details(':transactionId'), component: React.lazy(() => import('./views/transactions/TransactionDetails')) },
  { path: paths.banking.transactions.listByAccountId(':accountId'), component: Transactions },
  { path: paths.banking.transactions.list(), component: Transactions },

  { path: paths.recipients.details(':recipientId'), component: React.lazy(() => import('./views/recipients/RecipientDetails')) },
  { path: paths.recipients.list(), component: React.lazy(() => import('./views/recipients/Recipients')) },

  { path: paths.developer(), component: React.lazy(() => import('./views/developer/Developer')) },

  { path: paths.onboarding.documents(), exact: true, component: React.lazy(() => import('./views/onboarding/AdditionalDocuments')) },
  { path: paths.onboarding.selectType(), exact: true, component: React.lazy(() => import('./views/onboarding/SumSubSelectType')) },
  { path: paths.onboarding.questionnaireKyb(), exact: true, component: React.lazy(() => import('./views/onboarding/QuestionnaireKYB')) },
  { path: paths.onboarding.sumSub.start(':merchantType'), exact: true, component: VerificationSumSub },
  { path: paths.onboarding.sumSub.continue(), exact: true, component: VerificationSumSub },

  { path: paths.banking.finalStatus.fail(':method'), params: { isSuccess: false }, component: TransactionFinalStatus },
  { path: paths.banking.finalStatus.success(':method'), params: { isSuccess: true }, component: TransactionFinalStatus },

  { path: '*', component: React.lazy(() => import('./views/static/Error404')) }
]

export default routes
